/*@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf") format("truetype");
}
*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}

*{
  font-family: 'Open Sans', sans-serif !important;

}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
 /* border-radius: 5px;*/
}

::-webkit-scrollbar-track {
  background-color: transparent;
}






.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img, .swiper-slide a {
  display: block;
  /*width: 100%;*/
  /*height: 100%;
  object-fit: cover;*/
}

.swiper-pagination-bullet-active{
  width: 30px!important;
  height: 8px!important;
  border-radius: 99px!important;
  background: #722282!important;
}


.swiper-button-prev:after, .swiper-button-next:after{
  font-size: 25px !important;
  color: #722282!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.customSlider .swiper-wrapper {
  margin-bottom: 1.5rem !important;
}
