#myCartMenuProductImg {
    width: 100%;
    object-fit: contain;
    height: 100px;
  }
  
  /* set media query */
  @media (max-width: 900px) {
    #myCartMenuProductImg {
      height: 100px;
    }
  }
  