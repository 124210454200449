#productDetailsImage {
  width: 100%;
  margin-bottom: 3rem;
  object-fit: contain;
  height: 400px;
}

/* set media query */
@media (max-width: 900px) {
  #productDetailsImage {
    height: 250px;
  }
}
